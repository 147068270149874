import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import "./DetailsModal.css";

const DetailsModal = ({ isOpen, closeModal, course }) => {
  const CourseContent = course.contentComponent;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = [
    {
      src: course.imgUrl,
      altText: course.title,
    },
    // Add more items for the carousel as needed
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const carouselSlides = items.map((item, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <img src={item.src} alt={item.altText} />
    </CarouselItem>
  ));

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      size="lg" // You can adjust the modal size as needed
      className="details-modal"
    >
      <ModalHeader toggle={closeModal}>{course.title}</ModalHeader>
      <ModalBody>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          ride="carousel"
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {carouselSlides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
        <CourseContent />
        {/* Additional content or details go here */}
      </ModalBody>
    </Modal>
  );
};

export default DetailsModal;
