import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import "./FreeDetailsModal.css";

const FreeDetailsModal = ({ isOpen, toggle, course }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = [
    {
      src: course.imgUrl,
      altText: course.title,
    },
    // Add more items for the carousel as needed
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const carouselSlides = items.map((item, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <img src={item.src} alt={item.altText} className="modal-img" />
    </CarouselItem>
  ));

  // Determine which content component to render based on the selected course
  let contentComponent = null;
  if (course.contentComponent) {
    contentComponent = React.createElement(course.contentComponent, course);
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="details-modal">
      <ModalHeader toggle={toggle}>
        {course.title}
        <button className="close-button" onClick={toggle}>
          &times;
        </button>
      </ModalHeader>
      <ModalBody>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {carouselSlides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>

        {/* Render the selected course's content */}
        {contentComponent}
      </ModalBody>
    </Modal>
  );
};

export default FreeDetailsModal;
