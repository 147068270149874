import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/hero-img1.jpg";
import "./hero-section.css";
import { RiBookLine, RiProjectorLine } from "react-icons/ri"; // Import Remixicon icons

const HeroSection = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 hero__title">
                Anytime Anywhere <br /> Learn on your <br /> Suitable Schedule
              </h2>
              <p className="mb-5">
              Join us at KRISHNAS for flexible learning that fits your schedule. Discover the power of research-driven training and internal projects that prepare you for your dream career while helping us build our future workforce.
              </p>
            </div>
            <div className="button">
              <button className="btn square-btn">
                <RiBookLine className="icon" /> View Course
              </button>
              <button className="btn square-btn">
                <RiProjectorLine className="icon" /> View Projects
              </button>
            </div>
          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
