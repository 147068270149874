import React from "react";
import "./CourseContent.css"; // Add CSS for styling

const CourseContent2 = () => {
  // Define the Google Drive PDF URL
  const pdfUrl = "https://drive.google.com/file/d/1eGVERbumfUIKMESwXSmzoYwrpxc53-f8/view?usp=drive_link";

  // Function to handle the PDF download
  const handleDownloadPDF = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    
      <div>
        <h3>Core Engine Developer Internship Training</h3>
        <p>
          Welcome to the Core Engine Developer Internship Training program! This immersive two-week internship is designed to provide you with valuable knowledge and hands-on experience needed to excel as a Core Engine Developer (Full-Stack Developer) for web browser projects. Whether you're a recent graduate or a professional looking to transition into this exciting field, this internship offers a structured learning path to success.
        </p>
        
        <h4>Internship Duration: 2 Weeks</h4>
  
        <h4>Who Should Participate?</h4>
        <p>This internship is ideal for:</p>
        <ul>
          <li>Recent graduates interested in web browser development.</li>
          <li>Professionals seeking to switch careers into full-stack development.</li>
          <li>Anyone with a passion for web technology and a desire to become a Core Engine Developer.</li>
        </ul>
  
        <h4>Why Choose Us:</h4>
        <ul>
          <li>Experienced Mentors: Learn from industry experts with hands-on experience in web browser development.</li>
          <li>Comprehensive Curriculum: Cover key aspects of Rust, GPU optimization, web rendering, and browser components in just two weeks.</li>
          <li>Practical Learning: Apply your knowledge through hands-on projects and real-world scenarios.</li>
          <li>Internship Certificate: Receive a certificate to showcase your expertise as a Core Engine Developer.</li>
        </ul>
  
        <h4>What You'll Gain</h4>
        <h5>Week 1: Understanding Web Browser Architecture and Core Rust Concepts</h5>
        <ul>
          <li>Day 1: Introduction to Web Browsers and Browser Architecture</li>
          <li>Day 2: Introduction to Rust Programming</li>
          <li>Day 3: Rust Basics - Syntax and Variables</li>
          <li>Day 4: Rust Functions and Control Flow</li>
          <li>Day 5: System-Level Programming with C/C++</li>
          <li>Day 6: GPU Basics and Web Rendering Concepts</li>
          <li>Day 7: Summarizing Week 1 and Preparing for Week 2</li>
        </ul>
  
        <h5>Week 2: Advanced Topics and Browser Components</h5>
        <ul>
          <li>Day 8: Advanced Rust Programming - Ownership and Lifetimes</li>
          <li>Day 9: GPU Optimization for WebRender</li>
          <li>Day 10: Understanding Servo/WebKit Architecture with Rust Integration</li>
          <li>Day 11: Developing the High-Performance Proxy Layer for DOM in Rust (Part 1)</li>
          <li>Day 12: Developing the High-Performance Proxy Layer for DOM in Rust (Part 2)</li>
          <li>Day 13: Developing the High-Performance Proxy Layer for DOM in Rust (Part 3)</li>
          <li>Day 14: Summarizing Week 2 and Preparing for Further Learning</li>
        </ul>
  
        <h4>Internship Benefits</h4>
        <p>By completing this internship, you will:</p>
        <ul>
          <li>Gain a solid foundation in web browser architecture and Rust programming.</li>
          <li>Acquire practical skills in GPU optimization and web rendering.</li>
          <li>Develop real-world components for web browsers, including a Proxy Layer for DOM.</li>
          <li>Receive mentorship and guidance for seamless integration into a development team.</li>
        </ul>
  
        <h4>Prerequisites</h4>
        <p>To succeed in this internship, it's recommended to have:</p>
        <ul>
          <li>Basic programming knowledge (not mandatory).</li>
          <li>A laptop or desktop computer with internet access.</li>
          <li>Eagerness to learn and a passion for web technology.</li>
        </ul>
  
        <h4>Internship Certificate</h4>
        <p>
          Upon successful completion of this internship, you will receive a certificate of achievement, showcasing your proficiency as a Core Engine Developer (Full-Stack Developer) ready to contribute to web browser projects.
        </p>
  
        <h4>Join Us Today!</h4>
        <p>
          Join us on this exciting internship journey into the world of web browser development! Enroll today and take the first step toward becoming a skilled Core Engine Developer.
        </p>
  
        <p>
        <strong>Have Questions?</strong>
        <br />
        Contact our friendly support team at hari@krishnas.org or krishnasresearchcenter@gmail.com or call +918304088364 for assistance.
      </p>
              {/* Button to download PDF */}
      <button className="download-button" onClick={handleDownloadPDF}>
        Download Internship Details
      </button>
      </div>
    );
  };
  

export default CourseContent2;