import React, { useState, useRef, useEffect } from "react"; // Import useEffect from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import "./ProjectModal.css";

import image1 from "../../assests/images/testimonial01.jpg";
import image2 from "../../assests/images/testimonial02.jpg";
import image3 from "../../assests/images/testimonial03.jpg";


const ProjectModal = ({ isOpen, closeModal, project }) => {
  const modalRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    if (isFullscreen) {
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    }

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
    };
  }, [isFullscreen]);

  const handleFullscreenChange = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setIsFullscreen(false);
      setAutoplay(false);
    }
  };

  if (!isOpen || !project) {
    return null;
  }

  const handleCloseClick = () => {
    closeModal();
    setIsFullscreen(false);
    setAutoplay(false);
  };

  const handleMaximizeClick = () => {
    if (!isFullscreen) {
      if (modalRef.current.requestFullscreen) {
        modalRef.current.requestFullscreen();
      } else if (modalRef.current.mozRequestFullScreen) {
        modalRef.current.mozRequestFullScreen();
      } else if (modalRef.current.webkitRequestFullscreen) {
        modalRef.current.webkitRequestFullscreen();
      } else if (modalRef.current.msRequestFullscreen) {
        modalRef.current.msRequestFullscreen();
      }
      setAutoplay(true);
    } else if (
      document.fullscreenElement ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      setAutoplay(false);
    }

    setIsFullscreen(!isFullscreen);
  };

  const modalClasses = `project-modal ${isFullscreen ? "maximized" : ""}`;

  const carouselItems = [
    {
      src: image1,
      altText: "Image 1",
      header: "About RTA Browser:",
      content:
        "RTA Browser is designed with a vision to redefine the way we experience the web. With a focus on speed, privacy, and adaptability, we're bringing a revolutionary product to life, ensuring that every user feels empowered and secure online.",
    },
    {
      src: image2,
      altText: "Image 2",
      header: "RTA Browser Project",
      subHeader: "Vision:",
      content:
        "To pioneer a new era of internet browsing where users are empowered with unparalleled speed, privacy, and adaptability. Mission: We commit to developing a browser that ensures user privacy, champions decentralization, and provides state-of-the-art speed, reliability, and customization options.",
    },
    {
      src: image3,
      altText: "Image 3",
      header: "Future Vision",
      content:
        "We're continuously innovating to offer more features and functionalities that go beyond just a web browser. Stay tuned!",
    },
  ];

  const next = () => {
    if (activeIndex === carouselItems.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const previous = () => {
    if (activeIndex === 0) {
      setActiveIndex(carouselItems.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  const slides = carouselItems.map((item, index) => (
    <CarouselItem key={index}>
      <img src={item.src} alt={item.altText} />
      <div className="carousel-caption">
        <h4>{item.header}</h4>
        {item.subHeader && <h5>{item.subHeader}</h5>}
        <p>{item.content}</p>
      </div>
    </CarouselItem>
  ));

  return (
    <div className={modalClasses} ref={modalRef}>
      <div className="project-modal-header">
        <h2>{project.title}</h2>
        <div className="project-modal-header-buttons">
          <button className="maximize-button" onClick={handleMaximizeClick}>
            <i
              className={`far fa-window-${isFullscreen ? "restore" : "maximize"}`}
            ></i>
          </button>
          <button className="close-button" onClick={handleCloseClick}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={autoplay ? 5000 : false}
      >
        <CarouselIndicators
          items={carouselItems}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
      <div className="scrollable-content">
        <h3>RTA Browser</h3>

        <h3>Overview</h3> 
        <p>
          Welcome to the future of browsing! With RTA Browser, experience the
          next level of secure, efficient, and interactive web exploration,
          powered by Rust and cutting-edge technology.
        </p>       

        <h3>About the RTA Browser Project</h3>
        <p>
          What is the RTA Browser Project?
          <br />
          The RTA Browser Project is an ambitious initiative by KRISHNAS
          aiming to provide a decentralized, private, and secure browsing
          experience for users worldwide. Through cutting-edge technology and
          innovative design, the RTA Browser is poised to change the way we
          think about internet browsing.
        </p>

        <h3>Goals</h3>
        <ul>
          <li>User Empowerment: To grant users unparalleled control over their
            browsing data, ensuring maximum privacy and data security.
          </li>
          <li>Promote Decentralization: To create a platform that isn't
            monopolized by major corporations, ensuring fair access and control
            of the internet to all users.
          </li>
          <li>Revolutionize Speed: To guarantee fast load times and efficient
            use of resources, minimizing lag and maximizing user experience.
          </li>
          <li>Customization & Flexibility: To offer users an adaptable
            interface with extensive personalization features, allowing for a
            browser that feels truly personal.
          </li>
          <li>Education & Community: To foster a community of users and
            developers around the RTA Browser, promoting education and
            collaborative growth.
          </li>
        </ul>

        <h3>Features</h3>
        <ul>
          <li>Enhanced Privacy Mode
            <br />
            Never worry about your online footprint again. With our built-in
            feature, you can browse without leaving any traces.
          </li>
          <li>Decentralized Architecture
            <br />
            Experience the power of decentralization. No more reliance on
            centralized servers. Your data. Your control.
          </li>
          <li>Optimized Loading
            <br />
            Say goodbye to slow load times. With our unique algorithms, web
            pages load at lightning speed.
          </li>
          <li>Adaptive UI
            <br />
            Customize to your heart's content. RTA browser adapts to you, not
            the other way around.
          </li>
          <li>Built-in VPN
            <br />
            Surf securely with our integrated VPN. Say no to trackers and prying
            eyes.
          </li>
          <li>Community Forum
            <br />
            Join a thriving community of RTA users and developers. Share,
            learn, and grow together.
          </li>
          <li>Cross-Platform Syncing
            <br />
            Keep your data synchronized across devices. Browse on one, pick up
            on another.
          </li>
          <li>Extensions & Plugins
            <br />
            Enhance your browser with a plethora of tools. Your browser, your
            rules.
          </li>
          <li>Advanced Search
            <br />
            Dive deep into the web with our advanced search capabilities. Get
            accurate results faster.
          </li>
          <li>Eco Mode
            <br />
            Browse responsibly. With Eco Mode, save energy and reduce your
            carbon footprint.
          </li>
        </ul>

        <h3>How it Works</h3>
        <p>
          A step-by-step guide on the inner workings of the RTA Browser,
          simplifying complex operations for our audience.
        </p>

        <h4>Core Rendering Engine</h4>
        <ul>
          <li>WebKit: The heart of rendering, interpreting HTML & CSS.</li>
          <li>WebRender: The artist that paints pixels on your screen.</li>
          <li>And more!</li>
        </ul>

        <h4>High-Performance Proxy Layer for DOM</h4>
        <ul>
          <li>DOM Access Manager: Your gatekeeper to the Document Object Model.</li>
          <li>Virtual DOM Manager: The efficient twin of the real DOM.</li>
          <li>And more!</li>
        </ul>

        <h4>Networking & Decentralized Web Layer</h4>
        <ul>
          <li>Protocol Handlers: Speak the language of the web—HTTP, HTTPS, FTP, and WS.</li>
          <li>Web3 Handlers: Bridging you to the blockchain universe.</li>
          <li>And more!</li>
        </ul>

        <h4>User Experience Layer</h4>
        <ul>
          <li>UI Renderer: Where art meets function.</li>
          <li>Input Manager: Interactions made effortless.</li>
          <li>And more!</li>
        </ul>

        <h4>Security & Compliance Layer</h4>
        <ul>
          <li>SSL/TLS Manager: Where your security is our priority.</li>
          <li>Content Security Policy Manager: Keeping the web safe for you.</li>
          <li>And more!</li>
        </ul>

        <h3>Features Beyond Browsing</h3>
        <ul>
          <li>Search & Computing Engine
            <br />
            ChatModule: An intelligent chat agent for managing search, data
            processing, machine learning, and more. It's like having a ChatGPT
            in your browser!
            <br />
            NLPModule: Understands your queries and returns the most relevant
            results.
            <br />
            PyodideModule: Execute Python code right in your browser!
          </li>
          <li>Volunteer Computing & Data Center in a Browser
            <br />
            StorageService: Data operations managed efficiently.
            <br />
            BlockchainService: Enabling decentralized capabilities.
            <br />
            Processing Environments: Choose from local processing or harness the
            power of volunteer computing.
          </li>
          <li>Machine Learning Capabilities
            <br />
            Local ML: Train user-specific models without compromising data privacy.
            <br />
            Distributed ML: Harness the power of distributed computing for robust
            model training.
          </li>
          <li>Blockchain Layer
            <br />
            Smart Contracts: Execute logic and validations on Solana.
            <br />
            Token Operations: Digital tokens for a vibrant ecosystem.
          </li>
        </ul>

        <h3>Additional Requirements & Future Vision</h3>
        <p>
          We're continuously innovating to offer more features and
          functionalities that go beyond just a web browser. Stay tuned!
        </p>

        <h3>Get Involved</h3>
        <p>
          Ready to experience the future of web browsing? Download RTA Browser
          now!
          <br />
          For developers interested in contributing, check out our{" "}
          <a href="https://github.com/your-github-repo" target="_blank" rel="noopener noreferrer">
            GitHub Repository
          </a>
        </p>
      </div>
    </div>
  );
};

export default ProjectModal;
