import React from "react";
import { Container, Row, Col } from "reactstrap";

import chooseImg from "../../assests/images/why-choose-us.jpg";
import "./choose-us.css";

const ChooseUs = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="choose__content">
              <h2>About KRISHNAS</h2>
              <p>
                KRISHNAS, which stands for Kalam's Research Institute for Studies on Hybrid Technologies, Nanotech Advanced Apps, and Scientific Development, draws its inspiration from the visionary Dr. APJ Abdul Kalam.
                We are a dedicated research and training institute, passionately committed to advancing knowledge and expertise in the fields of Hybrid Technologies, Nanotech Advanced Apps, Economics, and Scientific Development. Our mission is to foster innovation, research excellence, and sustainable progress, all in honor of the great Dr. Kalam's enduring legacy.
                At KRISHNAS, we invite you to be a part of our vibrant community of thinkers, innovators, and learners. Join us on this journey of exploration, discovery, and development as we strive to make meaningful contributions to the world of science and technology.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
              <img src={chooseImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
