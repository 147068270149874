import React from "react";
import "./CourseContent.css"; // Add CSS for styling

const CourseContent3 = () => {
  // Define the Google Drive PDF URL
  const pdfUrl = "https://drive.google.com/file/d/13OYbhT5Njmf1TW1cGrKK7lUwhQxalFZ8/view?usp=drive_link";

  // Function to handle the PDF download
  const handleDownloadPDF = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div>
      <h3>Internship Training: Networking and Protocol Development for Decentralized Applications</h3>
      <p>
        Welcome to our intensive two-week internship training program in Networking and Protocol Development for Decentralized Applications! This hands-on experience is designed to equip you with the knowledge and practical skills required to excel in the world of decentralized technologies.
      </p>
      
      <h4>Internship Overview:</h4>
      <p>
        Are you passionate about building the backbone of decentralized applications, ensuring secure communication, and harnessing the power of blockchain and peer-to-peer networks? Dive into the exciting world of networking and protocol development during this internship.
      </p>
      
      <h4>Internship Highlights:</h4>
      <ul>
        <li>Duration: 2 weeks (10 days of training)</li>
        <li>Focus: Rust Programming, libp2p, Solana, IPFS, and Blockchain Interoperability</li>
        <li>Prerequisites: Basic programming knowledge (prior experience with Rust is beneficial but not mandatory)</li>
        <li>Instructors: Experienced professionals with a deep understanding of decentralized technologies</li>
      </ul>
      
      <h4>What You'll Learn:</h4>
      <p>During this internship, you will gain valuable insights and practical experience in:</p>
      <h5>Week 1: Basics of Rust and libp2p</h5>
      <ul>
        <li>Proficiency in Rust programming language.</li>
        <li>Understanding libp2p's architecture and components.</li>
        <li>Building a basic libp2p application for peer-to-peer communication.</li>
      </ul>
      <h5>Week 2: Solana, IPFS, and Advanced Topics</h5>
      <ul>
        <li>Exploring the Solana blockchain and smart contract development.</li>
        <li>Mastery of IPFS for decentralized file storage and retrieval.</li>
        <li>Diving into advanced Solana features, IPFS usage, and blockchain interoperability.</li>
      </ul>
      
      <h4>Internship Structure:</h4>
      <p>
        Our internship is thoughtfully structured to ensure a comprehensive learning experience. Each day includes a blend of theory and hands-on practice, enabling you to apply your knowledge immediately.
      </p>
      
      <h4>Who Should Attend:</h4>
      <p>This internship is ideal for:</p>
      <ul>
        <li>Aspiring blockchain developers</li>
        <li>Software engineers interested in decentralized networking</li>
        <li>Anyone keen on exploring the world of libp2p, Solana, and IPFS</li>
      </ul>
      
      <h4>Internship Materials:</h4>
      <p>You will have access to:</p>
      <ul>
        <li>Textbooks: "The Rust Programming Language" (for Rust) and "Mastering Blockchain, Second Edition" (for Solana, IPFS, and blockchain)</li>
        <li>Lecture notes and code samples</li>
        <li>Practical exercises and projects</li>
      </ul>
      
      <h4>Assessment and Certification:</h4>
      <p>
        Your understanding and skills will be assessed through practical tasks, collaborative projects, and presentations. Upon successful completion, you'll receive a certificate recognizing your achievements in Networking and Protocol Development for Decentralized Applications.
      </p>
      
      <h4>Why Choose Us:</h4>
      <ul>
        <li>Experienced Instructors: Learn from industry experts with hands-on experience in decentralized technologies.</li>
        <li>Comprehensive Curriculum: Cover key aspects of Rust, libp2p, Solana, IPFS, and blockchain in just two weeks.</li>
        <li>Practical Learning: Apply your knowledge through hands-on projects and real-world scenarios.</li>
        <li>Certification: Receive a certificate to showcase your expertise in decentralized networking and protocol development.</li>
      </ul>
      
      <h4>Enroll Today:</h4>
      <p>
        Join us for this exciting internship in the world of decentralized networking and protocol development. Kickstart your career or enhance your skills in this rapidly evolving field. Limited internship positions are available, so secure your spot now!
      </p>
      
      <p>
        <strong>Have Questions?</strong>
        <br />
        Contact our friendly support team at hari@krishnas.org or krishnasresearchcenter@gmail.com or call +918304088364 for assistance.
      </p>
            {/* Button to download PDF */}
            <button className="download-button" onClick={handleDownloadPDF}>
        Download Internship Details
      </button>
    </div>
  );
};

export default CourseContent3;