import React, { useState } from "react";
import "./testimonial.css";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import ProjectModal from "./ProjectModal"; // Import the modal component
import img1 from "../../assests/images/testimonial01.jpg";
import img2 from "../../assests/images/testimonial02.jpg";
import img3 from "../../assests/images/testimonial03.jpg";

const testimonialsData = [
  {
    img: img1,
    title: "RTA Browser",
    content:
      "Welcome to the future of browsing! With RTA Browser, experience the next level of secure, efficient, and interactive web exploration, powered by Rust and cutting-edge technology.",

  },
  {
    img: img2,
    title: "What is the RTA Browser Project?",
    content:
      "The RTA Browser Project is an ambitious initiative by KRISHNAS aiming to provide a decentralized, private, and secure browsing experience for users worldwide.",

  },
  {
    img: img3,
    title: "Vision",
    content:
    "To pioneer a new era of internet browsing where users are empowered with unparalleled speed, privacy, and adaptability.",

  },
];

const Testimonials = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  // Function to open the modal
  const openModal = (project) => {
    setSelectedProject(project);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedProject(null);
    setModalIsOpen(false);
  };

  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };

  return (
    <section className="testimonial-section">
      <Container>
        <Row>
          <Col lg="10" md="12" className="m-auto">
            <h2 className="testimonial-header">Active Projects</h2>
            <Slider {...settings}>
              {testimonialsData.map((item, index) => (
                <div key={index} className="testimonial-item">
                  <div
                    className="testimonial-image"
                    style={{ backgroundImage: `url(${item.img})` }}
                  ></div>
                  <div className="testimonial-content">
                    <h2>{item.title}</h2>
                    <p>{item.content}</p>
                    <button onClick={() => openModal(item)}>Read More</button>
                  </div>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
      <ProjectModal isOpen={modalIsOpen} closeModal={closeModal} project={selectedProject} />
    </section>
  );
};

export default Testimonials;
