import React from "react";
import { Container, Row, Col } from "reactstrap";
import reactLogo from "../../assests/images/logo/programing/react.ico";
import nodejsLogo from "../../assests/images/logo/programing/nodejs.ico";
import pyodideLogo from "../../assests/images/logo/programing/pyodide.ico";
import libp2pLogo from "../../assests/images/logo/programing/libptop.ico";
import rustLogo from "../../assests/images/logo/programing/rust.ico";
import slolanaLogo from "../../assests/images/logo/programing/slolana.ico";
import "./company-section.css"; // Import your CSS file


const Company = () => {
  return (
    <section>
      <Container>
        <Row className="company-logos">
          <Col lg="2" md="3" sm="4" xs="6" className="company-logo">
            <img src={reactLogo} alt="React Js" />
          </Col>

          <Col lg="2" md="3" sm="4" xs="6" className="company-logo">
            <img src={nodejsLogo} alt="Nodejs" />
          </Col>

          <Col lg="2" md="3" sm="4" xs="6" className="company-logo">
            <img src={pyodideLogo} alt="Pyodide" />
          </Col>

          <Col lg="2" md="3" sm="4" xs="6" className="company-logo">
            <img src={libp2pLogo} alt="Libp2p" />
          </Col>

          <Col lg="2" md="3" sm="4" xs="6" className="company-logo">
            <img src={rustLogo} alt="Rust" />
          </Col>

          <Col lg="2" md="3" sm="4" xs="6" className="company-logo">
            <img src={slolanaLogo} alt="Solana" />
          </Col>
        </Row>
      </Container>

        {/* AdSense Ad */}
        <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6001482870704731"
        data-ad-slot="1894624538"
        data-ad-format="auto"
      />
    </section>
  );
};

export default Company;
