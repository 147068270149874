import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import FreeCourseCard from "./FreeCourseCard";
import FreeDetailsModal from "./FreeDetailsModal";
import FreeCourseContent1 from "./FreeCourseContent1";
import FreeCourseContent2 from "./FreeCourseContent2";
import FreeCourseContent3 from "./FreeCourseContent3";
import freeCourseImg1 from "../../assests/images/blog1.jpg";
import freeCourseImg2 from "../../assests/images/blog2.jpg";
import freeCourseImg3 from "../../assests/images/blog3.jpeg";

const freeCoursesData = [
  {
    id: "01",
    title: "RTA Browser: Reinventing the Web Experience",
    description: "Welcome to the future of browsing! With RTA Browser.",
    imgUrl: freeCourseImg1,
    contentComponent: FreeCourseContent1,
  },
  {
    id: "02",
    title: "Exploring RTA Browser's Core Rendering Engine",
    description: "Understanding the Core Rendering Engine.",
    imgUrl: freeCourseImg2,
    contentComponent: FreeCourseContent2,
  },
  {
    id: "03",
    title: "High-Performance Proxy Layer for DOM",
    description: "The Significance of a High-Performance DOM Layer.",
    imgUrl: freeCourseImg3,
    contentComponent: FreeCourseContent3,
  },
  // Add more free courses here...
];

const FreeCourses = () => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const openDetailsModal = (course) => {
    setSelectedCourse(course);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setSelectedCourse(null);
    setDetailsModalOpen(false);
  };

  return (
    <section className="free-courses">
      <Container>
      <div className="free-courses-header">
          <h1>Recent Blogs</h1>
        </div>
        <Row>
          {freeCoursesData.map((item) => (
            <Col lg="4" md="6" sm="6" key={item.id}>
              <FreeCourseCard
                item={item}
                openDetailsModal={openDetailsModal}
              />
            </Col>
          ))}
        </Row>
      </Container>
      {selectedCourse && (
        <FreeDetailsModal
          isOpen={detailsModalOpen}
          closeModal={closeDetailsModal}
          course={selectedCourse}
        />
      )}
    </section>
  );
};

export default FreeCourses;
