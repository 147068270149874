import React from "react";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../assests/images/about-us.png";
import { FaGraduationCap, FaCertificate, FaBriefcase } from "react-icons/fa"; // Import icons
import "./about.css";

const AboutUs = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6" className="about__col">
           <div className="about__img">
            <img src={aboutImg} alt="" className="w-100" />
        </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__content">
              <h2>About Us</h2>
              <p>
              At KRISHNAS, we specialize in cutting-edge research and development. Our mission is to push the boundaries of knowledge and innovation, empowering individuals and organizations with the latest advancements in their respective fields.
              </p>
              
                  <div className="about__card">
                    <FaGraduationCap className="about__card-icon" />
                    <div className="about__card-content">
                      <h3 className="about__card-title">Online Courses</h3>
                      <p className="about__card-text">
                      Discover our online courses where knowledge meets innovation. Dive into research-driven curricula, engage in hands-on projects, and become an asset to us and future employers.
                      </p>
                    </div>
                  </div>

                  <div className="about__card">
                    <FaCertificate className="about__card-icon" />
                    <div className="about__card-content">
                      <h3 className="about__card-title">Earn a Certificate</h3>
                      <p className="about__card-text">
                      Earn globally recognized certificates, a symbol of your expertise. Our programs equip you with skills and showcase your readiness for our team. Begin your journey now.
                      </p>
                    </div>
                  </div>

                  <div className="about__card">
                    <FaBriefcase className="about__card-icon" />
                    <div className="about__card-content">
                      <h3 className="about__card-title">Be Part of our Projects</h3>
                      <p className="about__card-text">
                      Challenge yourself with captivating internal projects. They're more than assignments; they're glimpses of your future. Join us, learn, and leave a lasting mark on our success.
                      </p>
                    </div>
                  </div>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
