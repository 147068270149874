import React from "react";
import "./CourseContent.css"; // Add CSS for styling

const CourseContent5 = () => {
  // Define the Google Drive PDF URL
  const pdfUrl = "https://drive.google.com/file/d/1U_Ip652m4oEGLhvzafRw0jT8mKTOO-tS/view?usp=drive_link";

  // Function to handle the PDF download
  const handleDownloadPDF = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div>
      <h3>UI/UX Developer Internship Training with Rust and JavaScript</h3>
      <p>
        <strong>Internship Overview:</strong>
        <br />
        Welcome to the UI/UX Developer Internship Training program! This immersive internship offers a unique opportunity to gain practical experience in the dynamic field of UI/UX development while working with cutting-edge technologies like Rust and JavaScript. Over the course of four weeks, you will dive into web development, master UI/UX design principles, and apply your skills to real-world projects.
      </p>

      <p>
        <strong>Internship Highlights:</strong>
        <br />
        Duration: 4 weeks (20 days)
        <br />
        Skill Level: Beginner to Intermediate
        <br />
        Languages: English
        <br />
        Format: Online, hands-on training
      </p>

      <p>
        <strong>What You'll Gain:</strong>
        <br />
        <strong>Week 1: Foundations of Web Development and UI/UX Design</strong>
        <ul>
          <li>Build a strong foundation in web development fundamentals.</li>
          <li>Master HTML, CSS, and JavaScript for creating interactive web interfaces.</li>
          <li>Learn responsive design principles for creating user-friendly websites.</li>
          <li>Begin your journey into UI/UX design with wireframing.</li>
        </ul>
        <strong>Week 2: Advanced Web Development with Rust and JavaScript</strong>
        <ul>
          <li>Explore Rust programming language and its application in web development.</li>
          <li>Develop UI components using Rust and integrate them with JavaScript.</li>
          <li>Collaborate with peers using version control (Git) for efficient development.</li>
          <li>Optimize web designs for various screen sizes and devices.</li>
        </ul>
        <strong>Week 3: UI/UX Design Patterns and Best Practices</strong>
        <ul>
          <li>Analyze and implement common UI/UX design patterns for intuitive user interfaces.</li>
          <li>Understand the significance of accessibility and localization in UI/UX development.</li>
          <li>Apply advanced UI/UX design techniques for visually appealing interfaces.</li>
          <li>Create design documentation and wireframes for complex projects.</li>
        </ul>
        <strong>Week 4: Real-World UI/UX Projects and Presentation</strong>
        <ul>
          <li>Apply your skills to complete real-world UI/UX projects.</li>
          <li>Collaborate with fellow interns to integrate UI components and ensure compatibility.</li>
          <li>Optimize projects for responsiveness, accessibility, and performance.</li>
          <li>Present your final projects, showcasing your design decisions and skills.</li>
        </ul>
      </p>

      <p>
        <strong>Internship Materials:</strong>
        <br />
        <ul>
          <li>Engage with interactive video lectures led by industry professionals.</li>
          <li>Participate in hands-on coding exercises and practical projects.</li>
          <li>Access comprehensive reading materials and specialized books for each module.</li>
          <li>Utilize essential software tools such as Visual Studio Code, Figma, and Git.</li>
          <li>Complete practical assignments and have opportunities for peer reviews.</li>
          <li>Explore real-world case studies and examples in UI/UX development.</li>
        </ul>
      </p>

      <p>
        <strong>Prerequisites:</strong>
        <br />
        Basic knowledge of web development, Rust, or JavaScript is recommended.
      </p>

      <p>
        <strong>Internship Certificate:</strong>
        <br />
        Upon successful completion of the internship, you will receive a Certificate of Achievement, demonstrating your proficiency in UI/UX Development with Rust and JavaScript.
      </p>

      <p>
        <strong>Unlock Exciting Career Opportunities:</strong>
        <br />
        By the end of this internship, you'll be well-prepared for roles such as UI/UX Developer, Front-end Developer, or Web Designer. Your skills in Rust and JavaScript, coupled with your expertise in UI/UX principles, will open doors to rewarding job opportunities in the tech industry.
      </p>

      <p>
        <strong>Join Us Today:</strong>
        <br />
        Embark on your journey to becoming a skilled UI/UX Developer with Rust and JavaScript. Enroll now to secure your spot in this immersive internship and unlock a world of career opportunities.
      </p>

      <p>
        <strong>Have Questions?</strong>
        <br />
        Contact our friendly support team at hari@krishnas.org or krishnasresearchcenter@gmail.com or call +918304088364 for assistance.
      </p>
      {/* Button to download PDF */}
      <button className="download-button" onClick={handleDownloadPDF}>
        Download Internship Details
      </button>
    </div>
  );
};

export default CourseContent5;
