// Courses.jsx
import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import CourseCard from "./CourseCard";
import DetailsModal from "./DetailsModal";
import CourseContent1 from "./CourseContent1";
import CourseContent2 from "./CourseContent2";
import CourseContent3 from "./CourseContent3";
import courseImg1 from "../../assests/images/web-design.png";
import courseImg2 from "../../assests/images/graphics-design.png";
import courseImg3 from "../../assests/images/ui-ux.png";
import "./courses.css"; // Import your custom CSS

const coursesData = [
  {
    id: "01",
    title: "Comprehensive UI/UX Development with Rust and JavaScript",
    lesson: 12,
    students: 7.0,
    rating: 5.9,
    imgUrl: courseImg1,
    contentComponent: CourseContent1,
  },
  {
    id: "02",
    title: "Core Web Browser Engine Design with Rust and C++",
    lesson: 12,
    students: 8.0,
    rating: 5.9,
    imgUrl: courseImg2,
    contentComponent: CourseContent2,
  },
  {
    id: "03",
    title: "Networking and Blockchain Development",
    lesson: 12,
    students: 5.0,
    rating: 5.9,
    imgUrl: courseImg3,
    contentComponent: CourseContent3,
  },
  // Add more courses here...
];

const Courses = () => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const openDetailsModal = (course) => {
    setSelectedCourse(course);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setSelectedCourse(null);
    setDetailsModalOpen(false);
  };

  return (
    <section>
      <Container>
      <div className="courses-header">
          <h1>Specialised Training Modules for Interns</h1>
        </div>
        <Row>
          {coursesData.map((item) => (
            <Col lg="4" md="6" sm="6" key={item.id}>
              <CourseCard item={item} openDetailsModal={openDetailsModal} />
            </Col>
          ))}
        </Row>
      </Container>
      {selectedCourse && (
        <DetailsModal
          isOpen={detailsModalOpen}
          closeModal={closeDetailsModal}
          course={selectedCourse}
        />
      )}
    </section>
  );
};

export default Courses;
