import React from "react";
import "./FreeCourseContent.css"; // Import CSS for styling

const FreeCourseContent1 = ({ title, description }) => {
  return (
    <div className="free-course-content">

      <h4>Vision and Mission</h4>
      <p>
        <strong>Vision:</strong>
        <br />
        "To pioneer a new era of internet browsing where users are empowered with unparalleled speed, privacy, and adaptability."
      </p>
      <p>
        <strong>Mission:</strong>
        <br />
        "We commit to developing a browser that ensures user privacy, champions decentralization, and provides state-of-the-art speed, reliability, and customization options."
      </p>

      <h4>Our Vision: A Deeper Dive</h4>
      <p>
        The future we envision for web browsing is one where users are not just passive consumers but active participants. We want to empower users with the tools and features they need to take control of their online experiences.
      </p>

      <h4>User Empowerment</h4>
      <p>
        One of our primary goals is to grant users unparalleled control over their browsing data, ensuring maximum privacy and data security. We believe that your data should belong to you, not to big corporations. With RTA Browser, you decide what you share and what you keep private.
      </p>

      <h4>Promote Decentralization</h4>
      <p>
        The internet should be a level playing field where access and control are not monopolized by major corporations. RTA Browser aims to create a platform that ensures fair access and control of the internet to all users. We're committed to championing decentralization and making the web a more democratic space.
      </p>

      <h4>Revolutionize Speed</h4>
      <p>
        Slow loading times and laggy web experiences can be frustrating. RTA Browser guarantees fast load times and efficient use of resources. We're leveraging cutting-edge technology to minimize lag and maximize the speed of your online interactions.
      </p>

      <h4>Customization & Flexibility</h4>
      <p>
        No two users are alike, and their browsers shouldn't be either. RTA Browser offers users an adaptable interface with extensive personalization features. We want you to have a browser that feels truly personal, allowing you to tailor your web experience to your preferences.
      </p>

      <h4>Education & Community</h4>
      <p>
        We believe in the power of a vibrant developer and user community. RTA Browser is not just a product; it's a platform for collaborative growth. Join our community of developers and users to exchange knowledge, foster education, and shape the future of web browsing.
      </p>

      <h4>Architecture Design</h4>
      <p>
        To achieve our ambitious goals, we've designed RTA Browser with a robust and versatile architecture. Let's take a glimpse under the hood:
      </p>

      {/* Include additional course content as needed */}
    </div>
  );
};

export default FreeCourseContent1;

