import React, { useState } from "react";
import FreeDetailsModal from "./FreeDetailsModal";

const FreeCourseCard = (props) => {
  const { imgUrl, title, description, rating } = props.item;
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="single-free-course">
      <div className="free-course-img">
        <img src={imgUrl} alt="" className="w-100" />
        <button className="btn research-btn">Research</button>
      </div>

      <div className="free-course-details">
        <h6>{title}</h6>
        <p>{description}</p>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="d-flex align-items-center gap-2">
              <i className="ri-star-fill"></i> {rating}
            </span>
          </div>

          <div>
            <button className="btn readmore-btn" onClick={toggleModal}>
              Read More
            </button>
          </div>
        </div>
      </div>
      {modalOpen && (
        <FreeDetailsModal
          isOpen={modalOpen}
          toggle={toggleModal}
          course={props.item}
        />
      )}
    </div>
  );
};

export default FreeCourseCard;
